import { useEffect, useState } from 'react';
import {
  ConsentDetails as Details,
  ConsentResponse,
  Logger,
  RevokeDialog,
  Status,
  AccessFrequency,
} from '@adatree/atomic-components';
import { Box, Button, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { useConsentRepository } from '../../../app/hooks/repositories/consent-repository/useConsentRepository';
import ChevronLeft from 'mdi-material-ui/ChevronLeft';
import { useFeatureFlag } from '../../../app/hooks/feature-flag/feature-flag.hook';
import { APP_FLAGS } from '../../../app/consts/app.flags';
import { useDataRecipients } from '@adatree/atomic-components';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { trackError } from '../../../utils/errors/errors.util';

type ConsentDetailsParams = {
  consentId: string;
};

export const ConsentDetails = () => {
  const { dataRecipients } = useDataRecipients();
  const [getFlag] = useFeatureFlag();
  const [consentRepository] = useConsentRepository();
  const { consentId } = useParams<ConsentDetailsParams>();
  const pastUseCasetTitle = 'Data we received';
  const presentUseCasetTitle = 'Data we are currently receiving';
  const [consent, setConsent] = useState<ConsentResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [isProcessingRevoke, setIsProcessingRevoke] = useState<boolean>(false);
  const [useCasetTitle, setUseCasetTitle] = useState<string>(presentUseCasetTitle);
  const { appSettings } = useAppSettings();

  useEffect(() => {
    consentRepository.findConsentById(consentId).then((foundConsent: ConsentResponse) => {
      setConsent(Object.assign({}, foundConsent));
      if (foundConsent.status === Status.Expired || foundConsent.status === Status.Revoked) {
        setUseCasetTitle(pastUseCasetTitle);
      }
      // @ts-ignore

      if (
        foundConsent?.useCase?.accessFrequency !== AccessFrequency.OnceOff &&
        (getFlag(APP_FLAGS.showEditConsentBtn) || appSettings.consent.enableExtendConsent)
      ) {
        setCanEdit(true);
      }

      setIsLoading(false);
    });
  }, [consentId, consentRepository, getFlag, appSettings]);

  const handleRevokeDialogClose = () => {
    setOpenConfirmationDialog(false);
  };

  const handleRevokeButtonClick = () => {
    setOpenConfirmationDialog(true);
  };

  const handleRevokeDialogButtonClick = () => {
    setIsProcessingRevoke(true);

    consentRepository
      .revokeConsent(consentId)
      .then((revokedConsent) => {
        setConsent(revokedConsent);
        setUseCasetTitle(pastUseCasetTitle);
        setOpenConfirmationDialog(false);
        setIsProcessingRevoke(false);
      })
      .catch((error) => {
        Logger.error('Error handling Revoke', error);
        trackError(error);
        setOpenConfirmationDialog(false);
        setIsProcessingRevoke(false);
      });
  };

  return (
    <>
      {isLoading && (
        <>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </>
      )}
      {!isLoading && (
        <>
          <Box>
            <Button
              sx={{ mb: 2 }}
              variant="text"
              color="inherit"
              startIcon={<ChevronLeft />}
              href={URL_SETTINGS.CONSENT_LIST.url}
            >
              Back
            </Button>

            <Details
              consent={consent}
              dateTitle={'Key dates'}
              useCasetTitle={useCasetTitle}
              onRevokeClick={handleRevokeButtonClick}
              dataRecipients={dataRecipients}
              editUrl={canEdit ? URL_SETTINGS.CONSENT_EDIT.url.replace(':consentId', consentId) : undefined}
            />
          </Box>

          <RevokeDialog
            isOpen={openConfirmationDialog}
            isLoading={isProcessingRevoke}
            dataHolderName={consent.dataHolderName}
            onCancelClick={handleRevokeDialogClose}
            onRevokeClick={handleRevokeDialogButtonClick}
          />
        </>
      )}
    </>
  );
};
