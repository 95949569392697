import { Grantee, Logger } from '@adatree/atomic-components';
import { CONSTANTS } from '../../app/consts/app.const';
import { State } from '../../app/state/state';
import { User, UserManager } from 'oidc-client-ts';
import { AppSettings } from '../../app/settings/app.settings.types';
import { OtpSignInResponse, UserProfile } from '../../app/types/auth.type';
import { URL_SETTINGS } from '../../app/settings/url.settings';
import { AuthType } from '../../context/authentication/auth.context';
import { trackError } from '../errors/errors.util';
import jwt_decode from 'jwt-decode';

const getAccessToken = async (): Promise<string> => {
  const auth = State.getAuthManager();
  const authType = State.getAuthType();

  if (authType === AuthType.OTP) {
    const otpAuthManager = State.getOtpAuthManager();
    const accessToken = otpAuthManager ? otpAuthManager.accessToken : '';

    Logger.debug(`Using OTP access token ending in ${accessToken.slice(accessToken.length - 4)}`);
    return accessToken;
  }

  if (!auth) {
    return '';
  }

  return auth.getUser().then((user: User | null) => {
    const accessToken = user ? user.access_token : '';
    Logger.debug(`Using OIDC access token ending in ${accessToken.slice(accessToken.length - 4)}`);
    return accessToken;
  });
};

const setAccessToken = (accessToken: string) => {
  sessionStorage.setItem('ACCESS_TOKEN', accessToken);
};

const isAccessTokenExpired = (expiresInSeconds: number) => {
  const nowMillisecons = Date.now();
  const nowSeconds = nowMillisecons / 1000;
  return expiresInSeconds <= nowSeconds;
};

const parseOtpSignInResponse = (response: any, granteeId: string): OtpSignInResponse => {
  try {
    const decodedAccessToken: any = jwt_decode(response.access_token);
    const decodedIdToken: any = jwt_decode(response.id_token);
    let grantee: Grantee = undefined;
    let accessTokenExpires: number = Date.now();

    console.log('parseOtpSignInResponse', decodedIdToken, decodedAccessToken);

    if (decodedAccessToken.exp) {
      accessTokenExpires = decodedAccessToken.exp;
    }

    if (decodedIdToken.grantee) {
      grantee = {
        id: granteeId,
        licenceNumber: decodedIdToken.grantee.licenceNumber,
        name: decodedIdToken.grantee.name,
      };
    }

    const otpSignInResponse: OtpSignInResponse = {
      accessToken: response.access_token,
      accessTokenExpires: accessTokenExpires,
      idToken: response.id_token,
      email: decodedIdToken.email,
      mobile: '',
      grantee: grantee,
      claims: [],
    };

    return otpSignInResponse;
  } catch (error) {
    let errorMsg = 'Error parsing OtpSignInResponse';

    if (error && error.message) {
      errorMsg = `${errorMsg} - ${error.message}`;
    }
    throw new Error(errorMsg);
  }
};

const validateUriAndState = (state: string): boolean => {
  const localState = sessionStorage.getItem(CONSTANTS.storageKeys.authState);
  const currentUri = window.location.origin + window.location.pathname;
  let localRedirectUri;

  if (localState) {
    localRedirectUri = sessionStorage.getItem(localState);
  }

  if (localState === state && localRedirectUri === currentUri) {
    sessionStorage.removeItem(CONSTANTS.storageKeys.authState);
    sessionStorage.removeItem(localState);
    return true;
  } else {
    Logger.error(
      `CSRF check failed. Local state ${localState} and local URI ${localRedirectUri} do not match callback state ${state} and callback URI ${currentUri}`
    );
    return false;
  }
};

const initAuthentication = (settings: AppSettings): UserManager => {
  const userManager = new UserManager({
    authority: settings.oidc.authorityUri,
    client_id: settings.oidc.clientId,
    extraQueryParams: JSON.parse(settings.oidc.extraQueryParams),
    loadUserInfo: settings.oidc.loadUserInfo,
    metadata: {
      authorization_endpoint: settings.oidc.authorityUri,
      end_session_endpoint: settings.oidc.endSessionEndpoint,
      issuer: settings.oidc.issuer,
      jwks_uri: settings.oidc.jwksUri,
      token_endpoint: settings.oidc.tokenEndpoint,
      userinfo_endpoint: settings.oidc.userinfoEndpoint,
    },
    redirect_uri: settings.oidc.redirectUri,
    response_type: settings.oidc.responseType,
    scope: settings.oidc.scope,
  });

  userManager.clearStaleState();

  State.setAuthManager(userManager);

  return userManager;
};

const getEmail = (appSettings: AppSettings): string => {
  let email = '';

  const authType = State.getAuthType();

  if (authType === AuthType.OTP) {
    const otpAuthManager = State.getOtpAuthManager();
    return otpAuthManager ? otpAuthManager.email : '';
  }

  if (email === '') {
    const userProfile = getOidLocalUserProfile(appSettings);
    if (userProfile) {
      if (userProfile.email) {
        email = userProfile.email;
      } else if (userProfile.emails && Array.isArray(userProfile.emails) && userProfile.emails.length > 0) {
        email = userProfile.emails[0];
      }
    }
  }

  return email;
};

const getMobileNumber = (appSettings: AppSettings): string => {
  let mobileNumber = '';

  const authType = State.getAuthType();

  if (authType === AuthType.OTP) {
    const otpAuthManager = State.getOtpAuthManager();
    return otpAuthManager ? otpAuthManager.mobile : '';
  }

  if (mobileNumber === '') {
    const userProfile = getOidLocalUserProfile(appSettings);
    if (userProfile) {
      if (userProfile.phone_number) {
        mobileNumber = userProfile.phone_number;
      }
    }
  }

  return mobileNumber;
};

const getOidLocalUserProfile = (appSettings: AppSettings): UserProfile | undefined => {
  const item = sessionStorage.getItem(`oidc.user:${appSettings.oidc.authorityUri}:${appSettings.oidc.clientId}`);

  if (item) {
    try {
      return parseOidcUserProfile(item);
    } catch (error) {
      Logger.error('Error parsing oidc user profile data from session storage', error);
      trackError(error);
    }
  }
};

const parseOidcUserProfile = (item: string): UserProfile | undefined => {
  const data = JSON.parse(item);
  if (data.profile) {
    return data.profile;
  } else {
    return undefined;
  }
};

const signout = async (): Promise<void> => {
  if (State.getAuthType() === AuthType.OIDC) {
    const oidcAuth = State.getAuthManager();
    if (oidcAuth) {
      oidcAuth.removeUser();
      oidcAuth.signoutRedirect();
    }
  } else if (State.getAuthType() === AuthType.OTP) {
    State.setOtpAuthManager(undefined);
    window.location.href = URL_SETTINGS.OTP_SIGN_IN.url;
  }

  return;
};

export const AuthUtil = {
  initAuthentication,
  isAccessTokenExpired,
  getAccessToken,
  getEmail,
  getMobileNumber,
  getOidLocalUserProfile,
  setAccessToken,
  parseOtpSignInResponse,
  signout,
  validateUriAndState,
};
