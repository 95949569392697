import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import { Card, Logger, UseCaseResponse } from '@adatree/atomic-components';
import { FormInputText } from '../../molecules/form-components/form-input-text.molecule';
import { FormInputNumber } from '../../molecules/form-components/form-input-number.molecule';
import { useEffect, useState } from 'react';

interface Props {
  defaultValues: Record<string, string>;
  useCase: UseCaseResponse;
  onSubmit: (data: Record<string, string>) => void;
}
export const ConsentCustomData = ({ defaultValues, useCase, onSubmit }: Props) => {
  const [formValues, setFormValues] = useState(defaultValues);
  const { control, handleSubmit: handleSubmitForm, reset } = useForm({ defaultValues: formValues });
  let customData = undefined;

  try {
    customData = JSON.parse(useCase.customDataSchema);
    Logger.debug(customData);
  } catch (error) {
    Logger.error('Invalid Custom Data Schema', useCase.customDataSchema, error);
    throw new Error(`Error: Invalid Custom Data Schema ${error}`);
  }

  useEffect(() => {
    setFormValues(defaultValues);
    reset(defaultValues);
  }, [defaultValues, reset, setFormValues]);

  const handleSubmit: SubmitHandler<Record<string, string>> = (data) => {
    onSubmit(data);
  };

  return (
    <>
      {customData && customData.purpose && (
        <Typography sx={{ mb: 2, px: { md: 4, lg: 8 } }}>{customData.purpose}</Typography>
      )}

      <form onSubmit={handleSubmitForm(handleSubmit)}>
        <Card sx={{ pt: { xs: 3, md: 4, lg: 6 }, px: { md: 4, lg: 8 } }}>
          {Object.keys(customData.properties).map((key) => {
            return (
              <Box key={key}>
                {customData.properties[key].uiComponent.inputType === 'text' && (
                  <FormInputText
                    name={key}
                    label={customData.properties[key].displayText}
                    control={control}
                    rules={customData.properties[key].rules}
                  />
                )}
                {customData.properties[key].uiComponent.inputType === 'number' && (
                  <FormInputNumber
                    name={key}
                    label={customData.properties[key].displayText}
                    control={control}
                    rules={customData.properties[key].rules}
                  />
                )}
                {/* {customData.properties[key].uiComponent.inputType === 'select' && (
                  <FormInputSelect
                    name={key}
                    label={customData.properties[key].displayText}
                    control={control}
                    rules={customData.properties[key].rules}
                    options={uiComponent.inputData}
                  />
                )} */}
              </Box>
            );
          })}
        </Card>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'right' }}>
          <Button variant="contained" type="submit">
            Next
          </Button>
        </Box>
      </form>
    </>
  );
};
