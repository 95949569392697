import { useEffect } from 'react';
import {
  Logger,
  ThemeProvider,
  DataRecipient,
  DataRecipientsProvider,
  CopyProvider,
  CopyBuilder,
  Industry,
  Helper,
} from '@adatree/atomic-components';
import { setApiInterceptors } from '../../../app/api/api';
import { themeOverride } from '../../../app/theme/theme-override';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Header } from '../navigation/header.organism';
import { Routes } from '../../../app/routes/routes';
import { Footer } from '../navigation/footer.organism';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { AppSettings } from '../../../app/settings/app.settings.types';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import '../../../app/theme/fonts/font.css';
import { APP_FLAGS_PREFIX } from '../../../app/consts/app.flags';
import { ErrorBoundary } from '../../../app/error/error-boundary';
import { MixpanelAnalytics } from '../analytics/mixpanel.organism';

export const BootstrapApp = () => {
  const navigate = useNavigate();
  const { appSettings } = useAppSettings();
  const appTheme = appSettings.theme;
  const adrRecipient = Helper.getAdrDataRecipients(appSettings.dataRecipients);
  const primaryRecipient = Helper.getPrimaryDataRecipients(appSettings.dataRecipients);

  useEffect(() => {
    document.body.style.background = appTheme.colour.backgrounds.page;
    document.body.style.backgroundColor = appTheme.colour.backgrounds.page;
    document.body.style.minHeight = '100vh';
  }, [appTheme.colour.backgrounds.page]);

  loadFlags();
  printAppStatus(appSettings);
  initBugTracking(appSettings, primaryRecipient);
  setApiInterceptors(navigate);

  return (
    <MixpanelAnalytics>
      <DataRecipientsProvider
        accreditationNum={appSettings.accreditationNumber}
        initialDataRecipients={appSettings.dataRecipients}
      >
        <CopyProvider initialCopy={CopyBuilder.generateCopy(adrRecipient, primaryRecipient, Industry.Banking)}>
          <ThemeProvider theme={appTheme} extendTheme={themeOverride()}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateRows: 'auto 1fr auto',
                minHeight: '100vh',
              }}
            >
              <Header />
              <ErrorBoundary appSettings={appSettings}>
                <Routes />
              </ErrorBoundary>
              <Footer />
            </Box>
          </ThemeProvider>
        </CopyProvider>
      </DataRecipientsProvider>
    </MixpanelAnalytics>
  );
};

const printAppStatus = async (appSettings: AppSettings) => {
  let simulatedItems = '';
  simulatedItems = (await appSettings.application.simulateAuthentication)
    ? simulatedItems + 'authentication,'
    : simulatedItems;

  Logger.info('App version', appSettings.application.version, appSettings.application.timestamp);

  if (simulatedItems) {
    Logger.info(`App running with simulated ${simulatedItems}`);
  }

  Logger.info('URL', window.location.href);

  Logger.debug('App running with debug enabled');
  Logger.debug('App settings', appSettings);
};

const initBugTracking = (appSettings: AppSettings, primaryRecipient: DataRecipient) => {
  Bugsnag.start({
    apiKey: appSettings.thirdParty.bugsnag.apiKey,
    appVersion: appSettings.application.version,
    releaseStage: `${primaryRecipient.name.replaceAll(' ', '-')}-${appSettings.stage}`.toLowerCase(),
    plugins: [new BugsnagPluginReact()],
  });
};

const loadFlags = () => {
  if (window && window.location && localStorage) {
    const searchParams = new URLSearchParams(window.location.search);

    for (const key of searchParams.keys()) {
      if (key.startsWith(APP_FLAGS_PREFIX)) {
        const value = searchParams.get(key);
        Logger.debug(`Storing flag ${key} with value ${value} into local storage`);
        localStorage.setItem(key, value ? value : 'false');
      }
    }
  }
};
