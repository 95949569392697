import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FeedbackMessage, Logger, useAnalytics } from '@adatree/atomic-components';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { useConsentRepository } from '../../../app/hooks/repositories/consent-repository/useConsentRepository';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { handleErrorRedirect, trackError } from '../../../utils/errors/errors.util';
import { CONSTANTS } from '../../../app/consts/app.const';
import queryString from 'query-string';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import Check from 'mdi-material-ui/Check';
import { DashboardAnalyticsEvents } from '../../../app/consts/analytics.const';
import { consentApi } from '@adatree/react-api-sdk';

export const ConsentCallback: React.FC = () => {
  const navigate = useNavigate();
  const { hash, search } = useLocation();
  const { appSettings } = useAppSettings();
  const { track } = useAnalytics();
  const {
    patchConsentAuthorization,
    isMutating,
    error: patchApiError,
  } = consentApi.usePatchConsentAuthorizationTrigger();
  const [consentRepository] = useConsentRepository();
  const [isProcessing, setIsProcessing] = useState(true);
  const [isError, setIsError] = useState(false);

  const consentFlowType = sessionStorage.getItem(CONSTANTS.storageKeys.consentFlowType);
  const consentId = sessionStorage.getItem(CONSTANTS.storageKeys.authConsentId);
  const querySearchParams = queryString.parse(search);
  const response: string = querySearchParams.response as string;

  const queryHashParams = queryString.parse(hash);
  const code: string = queryHashParams.code as string;
  const idToken: string = queryHashParams.id_token as string;
  const state: string = queryHashParams.state as string;
  const error: string = queryHashParams.error as string;
  const errorDescription: string = queryHashParams.error_description as string;

  useEffect(() => {
    if (!isMutating && patchApiError) {
      Logger.error('Patch Consent Authorization API error', patchApiError);
      trackError(patchApiError);
    }
  }, [isMutating, patchApiError]);

  useEffect(() => {
    const handleSuccess = (id: string) => {
      track(DashboardAnalyticsEvents.CONSENT_SUCCESS, '', '', '');

      const allowMultiConsent = sessionStorage.getItem(CONSTANTS.storageKeys.allowMultiConsent);

      if (allowMultiConsent && allowMultiConsent === 'true') {
        navigate(`${URL_SETTINGS.POST_CONSENT_FLOW.url}?${CONSTANTS.urlParamsKeys.consentId}=${id}`);
      } else if (appSettings.consent.successCreateConsentRedirectUrl) {
        const redirect = appSettings.consent.successCreateConsentRedirectUrl.replace('[consentId]', id);
        Logger.info(`Redirecting to ${redirect}`);
        window.location.href = redirect;
        setIsProcessing(false);
      } else {
        navigate(URL_SETTINGS.CONSENT_DETAIL.url.replace(':consentId', id));
      }
    };

    const handleError = (code: string, message: string, error?: Error) => {
      track(DashboardAnalyticsEvents.CONSENT_SUCCESS, '', '', '', code);

      if (error) {
        Logger.error(code, message, error);
        trackError(error);
      } else {
        Logger.error(code, message);
      }

      if (appSettings.consent.errorRedirectUrl) {
        handleErrorRedirect(appSettings.consent.errorRedirectUrl, code, message);
      }

      setIsError(true);
      setIsProcessing(false);
    };

    const isCsrfSafe = (): boolean => {
      const localState = sessionStorage.getItem(CONSTANTS.storageKeys.authState);
      const currentUri = window.location.origin + window.location.pathname;

      let localRedirectUri;
      let isSafe = false;

      if (localState) {
        localRedirectUri = sessionStorage.getItem(localState);
        sessionStorage.removeItem(localState);
        sessionStorage.removeItem(CONSTANTS.storageKeys.authState);
      }

      Logger.debug('Consent Callback checking for CSRF with values - ', {
        localState: localState,
        localRedirectUri: localRedirectUri,
        state: state,
        code: code,
        idToken: idToken,
      });

      if (localState === state && localRedirectUri === currentUri) {
        isSafe = true;
        Logger.debug('CSRF check passed');
      } else {
        handleError(
          'csrf_err',
          `CSRF check failed. Local state ${localState} and local URI ${localRedirectUri} do not match callback state ${state} and callback URI ${currentUri}`
        );
      }

      return isSafe;
    };

    const callProcessAuthorization = () => {
      consentRepository
        .processAuthorization(state, code, idToken, response)
        .then((id: string) => {
          handleSuccess(id);
        })
        .catch((error) => {
          handleError('pro_auth_err', 'Error calling processAuthorization', error);
        });
    };

    const callNewProcessAuthorization = async () => {
      Logger.info('Calling new Process Authorization flow');

      if (consentId) {
        const patchResponse = await patchConsentAuthorization({
          consentId: consentId,
          dataHolderAuthorizationResponse: {
            response: response,
          },
        });
        if (patchResponse) {
          Logger.info('Update successfull', patchResponse);
          handleSuccess(consentId);
        }
      } else if (!consentId) {
        handleError('mis_para_err', 'Consent Callback is missing the Consent ID');
      }
    };

    if (appSettings.consent.enableExtendConsent && consentFlowType === CONSTANTS.consentFlowType.edit) {
      if (patchApiError) {
        handleError('pro_auth_err', patchApiError.message, patchApiError);
      } else if (appSettings.consent.enableExtendConsent && response && consentId && !isMutating && !patchApiError) {
        callNewProcessAuthorization();
      }
    } else {
      if (error) {
        handleError(error, errorDescription ? errorDescription : `Consent Callback failed due to error ${error}`);
      } else if (response) {
        callProcessAuthorization();
      } else if (isCsrfSafe() && code && idToken && state) {
        callProcessAuthorization();
      } else {
        handleError('mis_para_err', 'Consent Callback did not received all the required hash parameters:');
      }
    }
  }, [
    code,
    idToken,
    state,
    navigate,
    consentRepository,
    appSettings.consent.successCreateConsentRedirectUrl,
    appSettings.consent.errorRedirectUrl,
    appSettings.consent.enableExtendConsent,
    error,
    errorDescription,
    patchConsentAuthorization,
    response,
    track,
    consentId,
    isMutating,
    patchApiError,
    consentFlowType,
  ]);

  return (
    <>
      {isProcessing && <FeedbackMessage message="Processing your response..." showSpinner={true} />}
      {!isProcessing && isError && (
        <FeedbackMessage
          message="Sorry we were not able to process your request. Please try again later."
          icon={<AlertCircle sx={{ fontSize: '56px', color: 'error.main' }} />}
        />
      )}
      {!isProcessing && !isError && (
        <FeedbackMessage message="Consent created." icon={<Check sx={{ fontSize: '56px', color: 'primary.main' }} />} />
      )}
    </>
  );
};
