import { useState } from 'react';
import { useAppSettings } from '../../../../providers/app-settings.provider';
import ConsentRepository from '../../../api/types/consent-repository';
import BackendConsentRepository from '../../../api/repositories/consent-repository/backend-consent-repository';

export const useConsentRepository = (): [ConsentRepository] => {
  const { appSettings } = useAppSettings();

  let repository: ConsentRepository = new BackendConsentRepository(appSettings);

  const [value] = useState(repository);

  return [value];
};
